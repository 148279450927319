<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id + '/negotiate'}">洽谈室管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加洽谈室</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="pageRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="洽谈室标题" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入洽谈室标题"></el-input>
        </el-form-item>
        <el-form-item label="洽谈室缩略图" prop="thumbnail">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="洽谈室内容" prop="content">
          <QuillBar v-model="pageForm.content" :contenttext="pageForm.content" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添 加</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Add',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      exhibitionTitle: '',
      pageForm: {
        exhibition: this.$route.params.m_id,
        sort: 0,
        title: '',
        thumbnail: '',
        content: ''
      },
      pageRules: {
        title: [
          { required: true, message: '请填写洽谈室标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        thumbnail: [
          { required: true, message: '请上传洽谈室缩略图', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getExhibitionTitle()
  },
  methods: {
    onSubmit () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/exhibition-negotiate', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/negotiate')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    cancel () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/negotiate')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    getExhibitionTitle () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    itemChange (val) {
      this.pageForm.content = val
    }
  }
}
</script>

<style scoped>
/deep/.el-radio{
  margin-bottom: 14px;
}
.page-content{
  width: 800px;
  margin-right: auto;
  margin-left: auto;
}
</style>
